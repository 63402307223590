<template>
    <div class="photo-order-detail" v-htmltit data-title="拍单详情">
        <go-back-btn></go-back-btn>
        <div v-if="isTop" class="photo-order-detail-top">
            <div v-if="basicInfo.state == 1" class="status status1"> 待处理 </div>
            <div v-if="basicInfo.state == 2" class="status status2"> 已处理 </div>
            <div v-if="basicInfo.state == 3" class="status status2"> 待支付 </div>
            <div v-if="basicInfo.state == 5" class="status status5"> 已失效 </div>
            <div v-if="basicInfo.state == 4" class="status status4"> 已完成 </div>
            <div v-if="isMore" class="order-tit"> 记录单信息</div>
            <div :class="isMore?'':'oneLine'"> 单据编号：{{basicInfo.record_order_no}} </div>
            <div v-if="isMore" class="tip"> 备注：{{basicInfo.remarks}} </div>
            <div v-if="isMore" class="time-list">
                <van-steps :active="active" direction="vertical" active-color="#2F2F2F">
                    <van-step v-for="(item,index) in progress" :key="index">
                        <div class="time-item">
                            <div>{{item.operation_date}}</div>
                            <div>
                                <span>{{item | progressFilter}}</span>
                                <!-- <span v-if="item.remarks"> 因 {{item.remarks}} </span>
                                <span v-if="item.operation_type == 1">上传照片</span>
                                <span v-if="item.operation_type == 2">录入记录</span>
                                <span v-if="item.operation_type == 3">修改记录</span>
                                <span v-if="item.operation_type == 4">取消订单</span>
                                <span v-if="item.operation_type == 5">系统自动取消</span>
                                <span v-if="item.operation_type == 6">作废了记录单</span>
                                <span v-if="item.operation_type == 7">支付了记录单</span> -->
                            </div>
                        </div>
                    </van-step>
                </van-steps>
            </div>
            <div class="isMore-box" @click="handleClickLookMore">
                 <div class="isMore" :class="isMore?'close':''">
                    <div>查看更多</div>
                    <div :class="isMore?'transform':''"><i style="font-size:0.75rem" class="iconfont icon-shuangxiajiantou-"></i></div>
                </div>
            </div>
        </div>
        <div class="swiper" 
            :style="{ height: swiperImgHeight+'px',top:(isTop === false?0:'' )}">
            <swiper :options="swiperOption" 
                :style="{ height:isTop?swiperImgHeight - 40 +'px':swiperImgHeight +'px' }" 
                ref="swiper" 
                @slideChangeTransitionEnd="onChange">
                <swiper-slide 
                    v-for="(item,index) in imgList"
                    :key="index">
                        <!-- <img 
                            ref="imglist"
                            class="img-box swiper-zoom-container" 
                            style="object-fit:contain;"  
                            :src="getImgSizeJpg(item, 5, 5)" /> -->
                        <van-image fit="contain"
                            ref="imglist"
                            class="img-box swiper-zoom-container"
                            :src="item">
                            <template v-slot:error>加载失败</template>
                            <template v-slot:loading>
                                <van-loading type="spinner" size="20" />
                            </template>
                        </van-image>
                </swiper-slide>
            </swiper>
            <div class="custom-indicator">
                <div class="swiper-button-prev"></div>
                <div>{{current + 1}}/ {{imgList.length}}</div>
                <div class="swiper-button-next"></div>
            </div>
        </div>
        <div ref="bottom" class="photo-order-detail-bottom">
            <template v-if="(basicInfo.state == 2 || basicInfo.state == 3 || basicInfo.state == 4  || basicInfo.state == 5) && (orderInfo.pages > 0)">
                <div class="isMore-box" @click="handleClickShowInfo">
                    <div class="isMore2" :class="showOrderInfo?'':'close'">
                        <div>{{page}}/{{orderInfo.pages}}</div>
                        <div :class="showOrderInfo?'':'transform'"><i style="font-size:0.75rem" class="iconfont icon-shuangxiajiantou-"></i></div>
                    </div>
                </div>
                <div v-if="showOrderInfo" class="swiper2-indicator">
                    <div class="left" v-if="page != 1" @click="handleClickPrev2"><van-icon name="arrow-left" /></div>
                    <div class="right" v-if="page < orderInfo.pages" @click="handleClickNext2"><van-icon name="arrow" /></div>
                </div>
                <div v-if="showOrderInfo">
                    <van-swipe class="swiper2" ref="Swipe2" :show-indicators="false" :touchable="false" :loop="false">
                        <van-swipe-item 
                            v-for="(item,index) in orderInfo.record_order_sku_list"
                            :key="index">
                            <div class="order-info-list">
                                <div class="order-info-item" v-if="item.goods_type == 0 || item.goods_type == 1">
                                    <div class="order-base-info">
                                        <div class="line-height1and5">商品名称：{{item.sku_name}}</div>
                                        <!-- <div class="line-height1and5">商品规格：{{item.sku_name}}</div> -->
                                        <div class="flex justify-content-between">
                                            <div class="line-height1and5">单价：{{item.record_order_details_list[0].single_price}}</div>
                                            <div class="line-height1and5">建议售价：{{item.record_order_details_list[0].suggest_price}}/支</div>
                                        </div>
                                        <div class="flex justify-content-between">
                                            <div class="line-height1and5">订购数量：{{item.record_order_details_list[0].goods_quantity}}</div>
                                            <div class="line-height1and5">总价：{{item.record_order_details_list[0].subtotal}}</div>
                                        </div>
                                    </div>
                                    <div class="order-other-info">
                                        <!-- <div class="flex justify-content-between">
                                            <div class="line-height1and5">左右眼：不区分</div>
                                            <div class="line-height1and5">光度：+0.50</div>
                                            <div class="line-height1and5">散光：+0.50</div>
                                        </div>
                                        <div class="flex justify-content-between">
                                            <div class="line-height1and5">下加光：-0.50</div>
                                            <div class="line-height1and5">散光轴位：—</div>
                                            <div class="line-height1and5">瞳距(mm)：—</div>
                                        </div> -->
                                        <div>
                                            <div class="line-height1and5">备注：{{item.record_order_details_list[0].remarks}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="order-info-item" v-if="item.goods_type == 2">
                                    <div class="order-base-info">
                                        <div class="line-height1and5">商品名称：{{item.sku_name}}</div>
                                        <!-- <div class="line-height1and5">商品规格：{{item.sku_name}}</div> -->
                                    </div>
                                    <div class="order-degrees-box">
                                            <div class="order-degrees-tit">
                                                <div class="order-degrees-th width4">序号</div>
                                                <div class="order-degrees-th width4">光度</div>
                                                <div class="order-degrees-th width4">散光</div>
                                                <div class="order-degrees-th width7">订购数量(只)</div>
                                                <div class="order-degrees-th width7">单价(元/只)</div>
                                                <div class="order-degrees-th width3">总价</div>
                                                <div class="order-degrees-th width7">建议零售价(元/只)</div>
                                                <div class="order-degrees-th width7">备注</div>
                                            </div>
                                        <div class="order-degrees-list">
                                            <div class="order-degrees-item" v-for="(childItem,childIndex) in item.record_order_details_list" :key="childIndex">
                                                <div class="order-degrees-th width4">{{childIndex + 1}}</div>
                                                <div class="order-degrees-th width4">{{childItem.sphere}}</div>
                                                <div class="order-degrees-th width4">{{childItem.cylinder}}</div>
                                                <div class="order-degrees-th width7">{{childItem.goods_quantity}}</div>
                                                <div class="order-degrees-th width7">{{childItem.single_price}}</div>
                                                <div class="order-degrees-th width3">{{(childItem.goods_quantity * childItem.single_price).toFixed(2)}}</div>
                                                <div class="order-degrees-th width7">{{childItem.suggest_price}}</div>
                                                <div class="order-degrees-th width7">{{childItem.remarks}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="order-info-item" v-if="item.goods_type == 3">
                                    <div class="order-base-info">
                                        <div class="line-height1and5">商品名称：{{item.sku_name}}</div>
                                        <!-- <div class="line-height1and5">商品规格：{{item.sku_name}}</div> -->
                                    </div>
                                    <div class="order-degrees-box2">
                                            <div class="order-degrees-tit">
                                                <div class="order-degrees-th width3">序号</div>
                                                <div class="order-degrees-th width3">光度</div>
                                                <div class="order-degrees-th width3">散光</div>
                                                <div class="order-degrees-th width6">订购数量(只)</div>
                                                <div class="order-degrees-th width6">单价(元/只)</div>
                                                <div class="order-degrees-th width3">总价</div>
                                                <div class="order-degrees-th width3">下加光</div>
                                                <div class="order-degrees-th width3">瞳距</div>
                                                <div class="order-degrees-th width3">散光轴位</div>
                                                <div class="order-degrees-th width7">建议零售价(元/只)</div>
                                                <div class="order-degrees-th width7">备注</div>
                                            </div>
                                        <div class="order-degrees-list">
                                            <div class="order-degrees-item" v-for="(childItem,childIndex) in item.record_order_details_list" :key="childIndex">
                                                <div class="order-degrees-th width3">{{childIndex + 1}}</div>
                                                <div class="order-degrees-th width3">{{childItem.sphere}}</div>
                                                <div class="order-degrees-th width3">{{childItem.cylinder}}</div>
                                                <div class="order-degrees-th width6">{{childItem.goods_quantity}}</div>
                                                <div class="order-degrees-th width6">{{childItem.single_price}}</div>
                                                <div class="order-degrees-th width3">{{(childItem.goods_quantity * childItem.single_price).toFixed(2)}}</div>
                                                <div class="order-degrees-th width3" style="word-wrap:break-word">{{(childItem.add_light)}}</div>
                                                <div class="order-degrees-th width3" style="word-wrap:break-word">{{(childItem.pupillary_distance)}}</div>
                                                <div class="order-degrees-th width3" style="word-wrap:break-word">{{(childItem.cylinder_axis)}}</div>
                                                <div class="order-degrees-th width7">{{childItem.suggest_price}}</div>
                                                <div class="order-degrees-th width7">{{childItem.remarks}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </van-swipe-item>
                    </van-swipe>
                </div>
            </template>
            <div class="bottom-box">
                <div>单据总计: <span class="price">{{basicInfo.record_order_money?basicInfo.record_order_money:'0.00'}}</span></div>
                <div v-if="basicInfo.state == 1" @click="cancelOrder" class="bottom-opt-btn cancel">
                    取 消
                </div>
                <!-- <div v-if="basicInfo.state == 2" @click="handleGoPay" class="bottom-opt-btn pay">
                    结 算
                </div>
                <div v-if="basicInfo.state == 3" @click="handleGoPayAgain" class="bottom-opt-btn pay">
                    支 付
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import './index.less'
import goBackBtn from "@/view/Public/goBackBtn"
import ceshiPng from "@/assets/img/ceshi.jpg"
import { formatTime } from  "@/libs/utils"
import { recordOrderBasic, recordOrderImg, recordOrderProgress, recordOrderDetail, recordOrderCancel ,orderPayAgain} from "@/apis/api"
import {wxConfig, compare} from '@/libs/utils'
import bshop_global from "@/libs/global"
// 拍照记录单操作状态
const recordOrderOperationMap = {
    1: {
        text: '上传',
        stepName: '上传照片'
    },
    2: {
        text: '录入',
        stepName: '录入记录'
    },
    3: {
        text: '修改',
        stepName: '修改了记录'
    },
    4: {
        text: '用户取消',
        stepName: '取消了单据'
    },
    5: {
        text: '系统取消',
        stepName: '作废了单据'
    },
    6: {
        text: '业务员作废',
        stepName: '作废了记录'
    },
    7: {
        text: '用户支付',
        stepName: '支付了关联订单'
    },
    8: {
        text: '用户下单',
        stepName: '确认了关联订单'
    },
    9: {
        text: '用户取消',
        stepName: '取消了订单'
    }
}
export default {
    name:"add-photo-order",
    components:{ goBackBtn },
    data(){
        return{
            isCancel:false,
            ceshiPng:ceshiPng,
            swiperImgHeight:0,
            active:0,
            current: 0,
            isTop:true,
            isMore:false,
            showOrderInfo:false,
            basicInfo:{},
            imgList:[],
            progress:[],
            orderInfo:[],
            page:1,
            pageSize:1,
            swiperOption:{
                slidesPerView: 'auto',
                centeredSlides:true,
                spaceBetween: 10,
                zoom : true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            }
        }
    },
    beforeRouteLeave(to,from,next){
        if(this.isCancel){
            if(to.name == 'photo-order'){
                to.meta.isfresh = true
            }
        }else{
            if(to.name == 'photo-order'){
                to.meta.isfresh = false
            }
        }
        next()
    },
    mounted(){
        this.getImgSwiperHeight()
        this.getBasicInfo()
        this.getImgList()
        this.getProgress()
        this.getDetail()
    },
    filters: {
        // 拍照记录单进度整理
        progressFilter (progress) {
            const str = {
                name: progress.operation_persion_name,
                reason: progress.remarks ? '因' + progress.remarks : '',
                type: recordOrderOperationMap[progress.operation_type].stepName
            }
            return str.name + ' ' + str.reason + str.type
        }
    },
    methods:{
        initImg(src, index){
            let that = this;
            let img = new Image();
            img.src = src; //最终显示的大图
            img.onload = function() {
                that.$refs.imglist[index].src = src
            }
        },
        getBasicInfo(){
            let params = {
                id:this.$route.query.id
            }
            recordOrderBasic(params).then(res=>{
                if(res.code == 0){
                    this.basicInfo = res.res
                }else{
                    this.$toast(res.msg)
                }
            })
        },
        getImgList(){
            let params = {
                id:this.$route.query.id
            }
            recordOrderImg(params).then(res=>{
                if(res.code == 0){
                    this.imgList = res.res.img_list
                    // this.imgList.map((item, index)=>{
                    //     this.initImg(item ,index)
                    // })
                }else{
                    this.$toast(res.msg)
                }
            })
        },
        getProgress(){
            let params = {
                id:this.$route.query.id
            }
            recordOrderProgress(params).then(res=>{
                if(res.code == 0){
                    let data = res.res.opertion_list;
                    data.map(item=>{
                        item.operation_date = formatTime(item.operation_date,'Y-M-D h:m')
                    })
                    this.progress = data
                }else{
                    this.$toast(res.msg)
                }
            })
        },
        getDetail(){
            let params = {
                id:this.$route.query.id,
                page_no:this.page,
                page_size:this.pageSize
            }
            let toast = this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0,
                loadingType: 'spinner',
            });
            recordOrderDetail(params).then(res=>{
                toast.clear();
                if(res.code == 0){
                    let data = res.res;
                    data.record_order_sku_list.map(item=>{
                        item.record_order_details_list.map(childItem=>{
                            childItem.sphere = Number(childItem.sphere).toFixed(2)
                        })
                    })
                    for(let i in data.record_order_sku_list){
                        data.record_order_sku_list[i].record_order_details_list.sort(this.sortNum('sphere')).reverse()
                    }
                    this.orderInfo = data
                    this.getImgSwiperHeight()
                }else{
                    this.$toast(res.msg)
                }
            })
        },
        sortNum(props){
            return function(a,b) {
                return a[props] - b[props];
            }
        },
        //点击取消订单
        cancelOrder(){
            this.$dialog.confirm({
                width:'88%',
                title: '提示',
                message: '确认取消吗？'
            }).then(() => {
                let params = {
                    record_id_list:[this.$route.query.id]
                }
                recordOrderCancel(params).then(res=>{
                    if(res.code == 0){
                        this.$toast("成功取消")
                        this.getBasicInfo()
                        this.getProgress()
                        this.isCancel = true
                    }else{
                        this.$toast(res.msg)
                    }
                })
            }).catch(()=>{})
        },
        //点击结算
        handleGoPay(){
            this.$router.push({
                name:"photo-order-confirm",
                query:{ "id":this.$route.query.id}
            })
        },
        handleGoPayAgain(){
            let toast = this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0,
                loadingType: 'spinner',
            });
            let params = {
                order_id:this.basicInfo.order_id
            }
            orderPayAgain(params).then(res=>{
                if(res.code == 0){
                    if(this.basicInfo.pay_type == 1){
                        this.$router.replace({ name:"payment-success" })
                    }else if(this.basicInfo.pay_type == 2){
                        wxConfig(res.res.wxJsApiParam);
                        //将返回的信息放在缓存中 以便重新支付时获取
                        localStorage.setItem("againRes",JSON.stringify(res.res.wxJsApiParam))
                    }else if(this.basicInfo.pay_type == 3){
                    }else if(this.basicInfo.pay_type == 4){
                        window.location.href =  bshop_global.bShopUrl + "/#/payinfo?code=" + res.res.orderId+"&customerId=" + localStorage.getItem('customerId') +"&sysOfficeId="+ localStorage.getItem('sysOfficeId');
                    } else if(this.basicInfo.pay_type == 5){
                        this.$router.replace({ name:"payment-success"})
                    }
                }else{
                    this.$toast(res.msg)
                }
                toast.clear();
            })
        },
        onChange() {
            let index = this.$refs.swiper.$swiper.activeIndex
            this.current = index;
        },
        handleClickPrev(){
            this.$refs.Swipe.prev()
        },
        handleClickNext(){
            this.$refs.Swipe.next()
        },
        handleClickPrev2(){
            this.page --
            this.getDetail()
        },
        //动态获取图片swiper高度
        getImgSwiperHeight(){
            let height = document.documentElement.clientHeight 
            this.$nextTick(()=>{
                this.swiperImgHeight = 
                    height
                    - this.$refs.bottom.offsetHeight
            })
        },
        handleClickNext2(){
            this.page ++
            this.getDetail()
        },
        handleClickLookMore(){
            this.isMore = !this.isMore
            if(this.isMore){
                this.showOrderInfo = false
            }
        },
        handleClickShowInfo(){
            this.showOrderInfo = !this.showOrderInfo
            if(this.showOrderInfo){
                this.isTop = false
                this.isMore = false
            }else{
                this.current2 = 0
                this.isTop = true
            }
            this.getImgSwiperHeight()
        }
    }
}
</script>